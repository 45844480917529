import React from 'react'

export const QuestionMark = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62051 6.33594C8.33345 6.57951 8.125 6.95085 8.125 7.50001H6.875C6.875 6.59082 7.23947 5.86841 7.81178 5.38281C8.37051 4.90873 9.09321 4.6875 9.79167 4.6875C10.4901 4.6875 11.2128 4.90874 11.7715 5.38281C12.3439 5.86842 12.7083 6.59083 12.7083 7.50001C12.7083 8.13824 12.5449 8.62457 12.2778 9.02508C12.0535 9.36163 11.7619 9.62183 11.5325 9.82654C11.5074 9.84889 11.4831 9.87057 11.4598 9.89162C11.2038 10.122 11.0108 10.3119 10.8694 10.5617C10.7341 10.8007 10.625 11.1347 10.625 11.6666H9.375C9.375 10.9485 9.52628 10.397 9.78161 9.94597C10.0308 9.50571 10.3587 9.20084 10.6236 8.96248C10.6441 8.94399 10.6642 8.92597 10.6838 8.90839C10.934 8.68369 11.1063 8.52889 11.2378 8.33169C11.3614 8.14627 11.4583 7.90345 11.4583 7.50001C11.4583 6.95086 11.2499 6.57952 10.9628 6.33595C10.6622 6.08085 10.239 5.9375 9.79166 5.9375C9.34429 5.9375 8.92115 6.08085 8.62051 6.33594Z"
      fill={color ? color : '#001529'}
    />
    <path
      d="M9.16699 13.3333H10.8337V14.9999H9.16699V13.3333Z"
      fill={color ? color : '#001529'}
    />
  </svg>
)
