import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from '../../images/check-icon'
import RegistrationForm from '../forms/registration'
import MinimalFooter from '../footer/minimal-footer'

const RegisterUser = ({ title, heading, list, bottomText }) => {
  return (
    <>
      <div className={'flex flex-col lg:flex-row min-h-screen'}>
        <div
          className={
            'bg-gray-100 lg:w-1/2 mx-auto w-full lg:pt-28 pb-6  order-2 lg:order-first'
          }
        >
          <div
            className={
              ' lg-container w-full flex flex-col justify-center mx-0 md:mx-auto lg:mx-0 2xl:mx-auto '
            }
          >
            <div className={'flex flex-col'}>
              <h1
                className={
                  ' text-[28px] md:text-[39px] lg:text-[39px] font-bold pt-6 md:pt-9 lg:pt-0 pb-4 md:pb-8'
                }
              >
                {title}
              </h1>
              <RegistrationForm />
            </div>
          </div>
        </div>
        <div
          className={
            'bg-orange-lighter h-auto relative lg:w-1/2 flex-1 pt-16 lg:pt-28 pb-6'
          }
        >
          <div
            className={
              'full-container mx-auto w-full flex flex-col place-content-center items-center h-full gap-y-8'
            }
          >
            <h3
              className={
                ' pt-12 text-[28px] md:text-[38px] lg:text-[39px] font-bold'
              }
            >
              {heading}
            </h3>
            <ul className={'flex flex-col gap-y-2'}>
              {list && (
                <>
                  {list.map((item, index) => (
                    <li
                      key={index}
                      className={
                        'flex flex-nowrap font-medium text-orange-dark text-base md:text-lg items-center'
                      }
                    >
                      <CheckIcon /> {item.text}
                    </li>
                  ))}
                </>
              )}
            </ul>
            <p className={'text-base md:text-lg font-semibold'}>{bottomText}</p>
          </div>
        </div>
      </div>
      <MinimalFooter />
    </>
  )
}

export default RegisterUser

RegisterUser.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  list: PropTypes.array,
  bottomText: PropTypes.string,
}
