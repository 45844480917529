import React from 'react'

export const EyeClosed = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8463 11.4575C18.7869 10.6407 19.5908 9.59293 20.181 8.31429L18.819 7.68571C16.0872 13.6048 7.91284 13.6048 5.18097 7.68571L3.81903 8.31429C4.40917 9.59294 5.21314 10.6407 6.15371 11.4575L3.89389 14.5583L5.10611 15.4417L7.36601 12.3409C8.56079 13.0639 9.89459 13.4816 11.25 13.594V16.5H12.75V13.594C14.1054 13.4816 15.4392 13.0639 16.634 12.3409L18.8939 15.4417L20.1061 14.5583L17.8463 11.4575Z"
      fill="black"
    />
  </svg>
)
