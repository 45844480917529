import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import RegisterUser from '../components/register-user'
import SEO from '../components/seo'

const RegisterTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const template = page.template.templateRegisterUser
  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <RegisterUser
        title={page.title}
        heading={template.title}
        list={template.list}
        bottomText={template.bottomText}
      />
    </Layout>
  )
}

export default RegisterTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpRegisterTemplate {
          templateName
          templateRegisterUser {
            bottomText
            title
            list {
              text
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
  }
`
