import React from 'react'

export const CheckIcon = ({ color, size }) => {
  if (color === 'blue')
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4997 9.83325L13.2063 21.1266L7.24634 15.1679L5.83301 16.5813L13.2063 23.9546L25.913 11.2479L24.4997 9.83325Z"
          fill="#001529"
        />
      </svg>
    )
  else if (!color && size === 16) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0199 5.02034L6.35327 11.687C6.158 11.8823 5.84142 11.8823 5.64616 11.687L2.97949 9.02034L3.6866 8.31323L5.99971 10.6263L12.3128 4.31323L13.0199 5.02034Z"
          fill="black"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4997 9.83325L13.2063 21.1266L7.24634 15.1679L5.83301 16.5813L13.2063 23.9546L25.913 11.2479L24.4997 9.83325Z"
          fill="#3A1A03"
        />
      </svg>
    )
  }
}