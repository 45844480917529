import React, { createRef, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, Form, Formik, useField, useFormikContext } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Link, navigate } from 'gatsby'
import { EyeOpen } from '../../images/eye-open'
import { EyeClosed } from '../../images/eye-closed'
import { CloseIcon } from '../../images/close-icon'
import addToMailChimp from 'gatsby-plugin-mailchimp'
import { CheckIcon } from '../../images/check-icon'
import spinner from '../../images/Ellipse.png'

import {
  enteredFieldValue,
  passwordToggleEvent,
  registerEvent,
} from '../google-tag-manager/events'
import { Popup } from 'reactjs-popup'
import { QuestionMark } from '../../images/questionmark'
import { register } from '../../services/authentication'

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().matches(/^\+.{6,19}$/, 'Må starte med "+" og uten mellomrom.').required(),
  password: Yup.string('')
    .required('')
    .min(8, 'Passordet må inneholde minst 8 tegn'),
  endUserAgreement: Yup.bool().oneOf([true]),
  privacyAgreement: Yup.bool().oneOf([true]),
  clinics: Yup.array().min(1),
  recaptcha: Yup.string(),
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  endUserAgreement: false,
  privacyAgreement: false,
  clinics: [],
  clinicsInput: '',
  recaptcha: '',
  newsletter: false,
}

const payload = (values, clinics) => {
  return {
    custUserFirstName: values.firstName,
    custUserLastName: values.lastName,
    custUserMobile: values.phone,
    custUserEmail: values.email,
    custUserPassword: values.password,
    custUserEUA: values.endUserAgreement,
    clinics: clinics.join(),
  }
}

const passwordWords = ['Svakt', 'Svakt', 'Greit', 'Bra', 'Sterkt']

const Tooltip = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Popup
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <button
          className={'flex items-center'}
          type={'button'}
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          <div
            className={`rounded-full flex justify-center items-center h-6 w-6
              ${isOpen ? 'bg-blue-lighter' : 'bg-gray-300'}
              `}
          >
            {isOpen ? <QuestionMark /> : <QuestionMark color={'#4D4D4D'} />}
          </div>
        </button>
      }
      position={['right top', 'bottom right']}
      closeOnDocumentClick
      arrow={true}
      arrowStyle={{ color: '#001529E5' }}
      closeOnEscape={true}
      on={'hover'}
      className={'shadow-2xl'}
    >
      <div className={'bg-[#001529E5] rounded-lg p-4 shadow-1 max-w-[285px]'}>
        <div className={'flex flex-col'}>
          <p className={'text-white'}>
            Vi bruker kun telefonnummeret ditt til å sende viktige varsler om
            bestillinger du eventuelt gjør via Proteket Online.
          </p>
        </div>
      </div>
    </Popup>
  )
}

const RegistrationForm = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [clinics, setClinics] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    console.log(error)
  }, [error])
  const recaptchaRef = createRef()
  const togglePassword = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword)
    passwordToggleEvent()
  }

  const addClinic = (value) => {
    if (value.length > 2) {
      setClinics((clinics) => [...clinics, value])
    }
  }

  const removeClinic = (item) => {
    setClinics((clinics) => clinics.filter((clinic) => clinic !== item))
  }

  const HiddenField = (props) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props)

    useEffect(() => {
      if (clinics.length > 0) {
        setFieldValue('clinics', clinics)
      }
    }, [setFieldValue])

    return (
      <>
        <input className={'hidden'} {...props} {...field} />
      </>
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={RegistrationSchema}
        onSubmit={async (values) => {
          try {
            registerEvent()
            await register(payload(values, clinics))
            await addToMailChimp(values.email, {
              FNAME: values.firstName,
              LNAME: values.lastName,
              PHONE: values.phone,
              MMERGE5: values.clinics,
              MMERGE11: values.newsletter ? 'Ja' : 'Nei',
              MMERGE12: 'website-registration',
            })
              .then((data) => {
                console.log(data)
              })
              .catch(() => {
                console.log('error')
              })
            setTimeout(navigate('/velkommen'), 2000)
          } catch (e) {
            if (e.response.status === 409) {
              setError(true)
              console.log(e)
            }
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          isValid,
          submitCount,
          isSubmitting,
        }) => (
          <Form id={'registration'} noValidate={true}>
            <div className={'flex flex-col gap-y-4 mb-4'}>
              <div className={'flex flex-col gap-y-4'}>
                <div className={'flex flex-col md:flex-row gap-x-6 gap-y-4'}>
                  <div className={'flex flex-col basis-full md:basis-1/2'}>
                    <label
                      htmlFor={'firstName'}
                      className={`text-base font-medium pb-2.5 ${
                        touched.firstName && errors.firstName
                          ? 'text-required'
                          : 'text-black'
                      }`}
                    >
                      Fornavn*
                    </label>
                    <Field
                      className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.firstName && errors.firstName
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                      name={'firstName'}
                      type={'text'}
                      placeholder={'Fornavn'}
                      onBlur={() => {
                        !values.recaptcha && recaptchaRef.current.executeAsync()
                        if (values.firstName.length > 0) {
                          enteredFieldValue('first name')
                        }
                      }}
                    />
                  </div>

                  <div className={'flex flex-col  basis-full md:basis-1/2'}>
                    <label
                      htmlFor={'lastName'}
                      className={`text-base font-medium pb-2.5 ${
                        touched.lastName && errors.lastName
                          ? 'text-required'
                          : 'text-black'
                      }`}
                    >
                      Etternavn*
                    </label>
                    <Field
                      className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.lastName && errors.lastName
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                      name={'lastName'}
                      type={'text'}
                      placeholder={'Etternavn'}
                      onBlur={() => {
                        !values.recaptcha && recaptchaRef.current.executeAsync()

                        if (values.lastName.length > 0) {
                          enteredFieldValue('last name')
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={'flex flex-col md:flex-row gap-x-6 gap-y-4'}>
                  <div className={'flex flex-col basis-full md:basis-1/2'}>
                    <label
                      htmlFor={'email'}
                      className={`text-base font-medium pb-2.5 ${
                        touched.email && errors.email
                          ? 'text-required'
                          : 'text-black'
                      }`}
                    >
                      E-postadresse*
                    </label>
                    <Field
                      className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.email && errors.email
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                      name={'email'}
                      type={'email'}
                      placeholder={'navn@eksempel.no'}
                      onBlur={() => {
                        !values.recaptcha && recaptchaRef.current.executeAsync()

                        if (values.email.length > 0) {
                          enteredFieldValue('e-mail')
                        }
                      }}
                    />
                  </div>

                  <div className={'flex flex-col basis-full md:basis-1/2'}>
                    <div className={'flex justify-between'}>
                      <label
                        htmlFor={'phone'}
                        className={`text-base font-medium pb-2.5 ${
                          touched.phone && errors.phone
                            ? 'text-required'
                            : 'text-black'
                        }`}
                      >
                        Mobilnummer*
                      </label>
                      <Tooltip />
                    </div>
                    <Field
                      className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.phone && errors.phone
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                      name={'phone'}
                      type={'text'}
                      placeholder={'+4799887766'}
                      onBlur={() => {
                        !values.recaptcha && recaptchaRef.current.executeAsync()

                        if (values.phone.length > 0) {
                          enteredFieldValue('phone')
                        }
                      }}
                    />
                    {errors.phone && values.phone && <small className={'text-required text-xs mt-1'}>{errors.phone}</small>}
                  </div>
                </div>
              </div>
              <div className={'flex flex-col'}>
                <label
                  htmlFor={'password'}
                  className={`text-base font-medium pb-2.5  ${
                    touched.password && errors.password
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  Passord*
                </label>
                <div className="relative w-full">
                  <Field
                    className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.password && errors.password
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }
               `}
                    name={'password'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={'•••••••••••••'}
                    onBlur={() => {
                      !values.recaptcha && recaptchaRef.current.executeAsync()

                      if (values.password.length > 0) {
                        enteredFieldValue('password')
                      }
                    }}
                  ></Field>
                  <button
                    onClick={(e) => togglePassword(e)}
                    type={'button'}
                    className="absolute inset-y-0 right-0 flex items-center px-2"
                    tabIndex={-1}
                  >
                    {!showPassword ? <EyeOpen /> : <EyeClosed />}
                  </button>
                </div>
                <PasswordStrengthBar
                  className={'pt-3'}
                  password={values.password}
                  scoreWords={passwordWords}
                  shortScoreWord={'For kort'}
                />
              </div>
              <div className={'flex flex-col'}>
                <label
                  htmlFor={'clinicsInput'}
                  className={`text-base font-medium pb-2.5 ${
                    touched.clinics && errors.clinics
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  Din(e) arbeidsplass(er)*
                </label>
                <div className="relative w-full">
                  <Field
                    className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700 h-12
                  ${
                    touched.clinics && errors.clinics
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                    name={'clinicsInput'}
                    type={'text'}
                    placeholder={'Skriv inn klinikknavn'}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault()
                        addClinic(values.clinicsInput)
                        setFieldValue('clinicsInput', '')
                      }
                    }}
                    onBlur={() => {
                      if (values.clinicsInput.length > 2) {
                        addClinic(values.clinicsInput)
                        setFieldValue('clinicsInput', '')
                      }
                      if (values.clinics.length > 0) {
                        enteredFieldValue('clinics')
                      }
                    }}
                  />
                  <button
                    onClick={() => {
                      addClinic(values.clinicsInput)
                      setFieldValue('clinicsInput', '')
                    }}
                    type={'button'}
                    tabIndex={-1}
                    className="absolute inset-y-2.5 right-3 flex items-center pb-[2px]  justify-center gap-x-1 h-[30px] w-[101px] font-medium rounded-full bg-gray-100 text-sm hover:bg-gray-200"
                  >
                    {<CheckIcon size={16} />} Legg til
                  </button>
                </div>
                <HiddenField name={'clinics'} />
              </div>
              {clinics.length > 0 && (
                <>
                  <p>Valgte klinikker</p>
                  <div className={'flex flex-wrap gap-1'}>
                    {clinics.map((item, index) => (
                      <div
                        key={index}
                        className={
                          'bg-orange-main w-max rounded-full py-3 pr-2.5 pl-4 text-white flex items-center gap-x-2'
                        }
                      >
                        {item}
                        <button
                          tabIndex={-1}
                          type={'button'}
                          onClick={() => removeClinic(item)}
                        >
                          <CloseIcon variant={'white'} />
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div
                className={'flex flex-row justify-start items-center gap-x-4'}
              >
                <Field
                  className={'rounded'}
                  name={'endUserAgreement'}
                  type={'checkbox'}
                  id={'endUserAgreement'}
                  onBlur={() => {
                    if (values.endUserAgreement) {
                      enteredFieldValue('end user agreement')
                    }
                  }}
                />
                <label
                  htmlFor={'endUserAgreement'}
                  className={`text-base ${
                    touched.endUserAgreement && errors.endUserAgreement
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  Jeg har lest og aksepterer Protektets{' '}
                  <a
                    className={
                      'border-b-2 border-orange-main hover:border-orange-lightHover'
                    }
                    href={'/sluttbrukeravtale'}
                    rel={'noreferrer'}
                    target="_blank"
                    tabIndex={-1}
                  >
                    sluttbrukeravtale*
                  </a>
                </label>
              </div>
              <div
                className={'flex flex-row justify-start items-center gap-x-4'}
              >
                <Field
                  className={'rounded'}
                  name={'privacyAgreement'}
                  type={'checkbox'}
                  id={'privacyAgreement'}
                  onBlur={() => {
                    if (values.privacyAgreement) {
                      enteredFieldValue('privacy agreement')
                    }
                  }}
                />
                <label
                  htmlFor={'privacyAgreement'}
                  className={`text-base ${
                    touched.privacyAgreement && errors.privacyAgreement
                      ? 'text-required'
                      : 'text-black'
                  }`}
                >
                  Jeg har lest og aksepterer Protektets{' '}
                  <a
                    className={
                      'border-b-2 border-orange-main hover:border-orange-lightHover'
                    }
                    href={'/personvernerklaering'}
                    rel={'noreferrer'}
                    target="_blank"
                    tabIndex={-1}
                  >
                    personvernserklæring*
                  </a>
                </label>
              </div>
              <div
                className={'flex flex-row justify-start items-center gap-x-4'}
              >
                <Field
                  className={'rounded'}
                  name={'newsletter'}
                  type={'checkbox'}
                  id={'newsletter'}
                  onBlur={() => {
                    if (values.newsletter) {
                      enteredFieldValue('newsletter')
                    }
                  }}
                />
                <label
                  htmlFor={'newsletter'}
                  className={`text-base  ${
                    errors.newsletter ? 'text-required' : 'text-black'
                  }`}
                >
                  Jeg samtykker til å motta markedsføring fra Proteket
                </label>
              </div>
              <ReCAPTCHA
                className={'invisible'}
                ref={recaptchaRef}
                sitekey={'6LfIfVkhAAAAANmWuKxc-hDUB2Jz442JU-jsYdBd'}
                onChange={(e) => setFieldValue('recaptcha', e)}
                size={'invisible'}
                onErrored={(e) => console.error(e)}
                name={'recaptcha'}
              />
              {!isValid && submitCount > 0 && !error && (
                <div
                  className={
                    'rounded-lg bg-error/40 px-5 py-4 mx-auto text-center'
                  }
                >
                  <p className={'text-errortext font-medium text-sm'}>
                    Ett eller flere felt er feil eller ikke fylt inn. Vennligst
                    prøv på nytt.
                  </p>
                </div>
              )}

              {error && (
                <div
                  className={
                    'rounded-lg bg-error/40 px-5 py-4 mx-auto text-center'
                  }
                >
                  <p>
                    En bruker med denne e-post adressen eksisterer allerede.
                    Vennligst{' '}
                    <Link
                      className={
                        'border-b-2 border-orange-main hover:border-orange-lightHover'
                      }
                      to={'/logg-inn'}
                    >
                      {' '}
                      logg inn
                    </Link>
                    , eller bruk en annen e-post adresse.
                  </p>
                </div>
              )}
            </div>
            <div className={'flex justify-center'}>
              <button
                className={
                  ' w-full text-center px-[18px] min-w-[158px] max-w-max  font-medium pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark'
                }
                type={'submit'}
              >
                {isSubmitting ? (
                  <img
                    src={spinner}
                    alt={'spinner'}
                    className={'animate-spin mx-auto'}
                  />
                ) : (
                  <p>Opprett bruker</p>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RegistrationForm
