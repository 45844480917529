import React from 'react'

export const EyeOpen = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8188 12.8143C16.087 6.89524 7.91266 6.89524 5.18079 12.8143L3.81885 12.1857C7.08697 5.10476 16.9127 5.10476 20.1808 12.1857L18.8188 12.8143Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.75C11.3096 12.75 10.75 13.3096 10.75 14C10.75 14.6904 11.3096 15.25 12 15.25C12.6904 15.25 13.25 14.6904 13.25 14C13.25 13.3096 12.6904 12.75 12 12.75ZM9.25 14C9.25 12.4812 10.4812 11.25 12 11.25C13.5188 11.25 14.75 12.4812 14.75 14C14.75 15.5188 13.5188 16.75 12 16.75C10.4812 16.75 9.25 15.5188 9.25 14Z"
      fill="black"
    />
  </svg>
)
