import React from 'react'

const MinimalFooter = () => {
  return (
    <div
      className={
        'bg-blue-dark  order-last w-screen pt-3 pb-[14px] lg:pt-5 lg:pb-6 flex justify-center items-center'
      }
    >
      <p className={'text-white'}>© Proteket. Alle rettigheter reserverte.</p>
    </div>
  )
}


export default MinimalFooter